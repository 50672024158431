import { atleast1AlphabetRegExp, cityRegExp, emailRegExp, phoneRegExp, poBoxRegExp, urlRegExp, wholeNumRegExp } from './regex';
import { RoutingNumbers } from '../components/models';

const validators = {
    addressLineOne: (val = '') => {
        let error = null;
        if (!val.trim()) {
            error = 'Address Line One is required';
        }else if(!atleast1AlphabetRegExp.test(val)) {
            error = 'Invalid: Atleast one alphabet is required';
        } else if (poBoxRegExp.test(val)) {
            error = 'Physical address cannot be a PO Box.';
        }
        return { addressLineOne: error };
    },
    // Atleast one alphabet is required 
    oneAlphaRequired: (val = '', field: string, label: string) =>  {
        let error = null;
        if (!val.trim() || val === '$') {
            error = label ? `${label} is required` : 'Required Field';
        } else if(!atleast1AlphabetRegExp.test(val.trim())) {
            error = 'Invalid: Atleast one alphabet is required';
        }
        return { [field]: error };
    },
    businessCategory: (val = '') => ({ businessCategory: !val.trim() ? 'Business Category is required' : null }),
    businessType: (val = '') => ({ businessType: !val.trim() ? 'Business Type is required' : null }),
    city: (val= '', field = 'city') => {
        let error = null;
        if (!val.trim()) {
            error = 'City is required';
        } else if (!cityRegExp.test(val.trim())) {
            error = 'Invalid City';
        } 
        return { [field]: error };
    },
    depositAccount: (val = '') => {
        let error = null;
        if (!val.trim()) {
            error = 'Deposit Account is required';
        } else if(val.length < 3) {
            error = "Invalid: Minimum 3 number is required"
        } else if (!wholeNumRegExp.test(val)) {
            error = 'Invalid: Must be a number';
        }
        return { depositAccount: error };
    },
    depositRouting: (val = '') => {
        let error = null;
        if (!val.trim()) {
            error = 'Deposit Routing Number is required';
        } else if (!RoutingNumbers.includes(+val)) {
            error = 'Invalid Routing Number';
        }
        return { depositRouting: error };
    },
    dob: (val = '') => {
        let error = null;
        const age = Math.floor((new Date().getTime() - new Date(val).getTime()) / 3.15576e+10)  
        if (!val.trim()) {
            error = 'DOB is required';
        } else if (val.length !== 10) {
            error = 'Invalid Date of Birth. Format must follow MM-DD-YYYY';
        } else if (age < 18 || age > 120) {
            error = 'Invalid Date of Birth. You must be at least 18 and still alive';
        }
        return { dob: error };
    },
    email: (val = '') => {
        let error = null;
        if (!val.trim()) {
            error = 'Email is required';
        } else if (!emailRegExp.test(val)) {
            error = 'Invalid Email Address';
        }
        return { email: error };
    },
    entityType: (val = '') => ({ entityType: !val.trim() || val === 'Sole Proprietorship' ? 'Legal Entity Type is required' : null }),
    percentOwnership: (val = '') => {
        let error = null;
        if (!val.trim()) {
            error = 'Percent Ownership is required';
        } else if (+val > 100 || +val < 25) {
            error = 'Percent Ownership Must be between 25 to 100';
        } else if (!wholeNumRegExp.test(val)) {
            error = 'Round to the nearest number';
        }
        return { percentOwnership: error };
    },
    phone: (val = '', field = 'phone') => {
        let error = null;
        if (!val.trim()) {
            error = 'Phone Number is required';
        } else if (!phoneRegExp.test(val)) {
            error = 'Invalid Phone Number';
        }
        return { [field]: error };
    },
    required: (val = '', field: string, label: string) => ({ [field]: !val.trim() ? `${label} is required` : null }),
    ssn: (val = '') => {
        let error = null;
        if (!val.trim()) {
            error = 'SSN is required';
        } else if (val.length !== 9) {
            error = 'Invalid Social Security Number. Format must follow XXX-XX-XXXX'
        }
        return { ssn: error };
    },
    website: (val = '') => ({
        website: val && !urlRegExp.test(val.startsWith('https://') ? val : `https://${val}`) ? 'Invalid format' : null,
    }),
    wholeNumber: (val = '', field: string, label?: string) => {
        let error = null;
        if (!val.trim() || val === '$') {
            error = label ? `${label} is required` : 'Required Field';
        } else if (!wholeNumRegExp.test(val)) {
            if(+val < 0) {
                error = `${label} can not be negative`;
            } else {
                error = 'Round to the nearest number';
            }
        }
        return { [field]: error };
    },
    zipcode: (val = '') => {
        let error = null;
        if (!val.trim()) {
            error = 'Zip Code is required';
        } else if (!wholeNumRegExp.test(val)) {
            error = 'Invalid Zip Code';
        } else if (val.length !== 5) {
            error = 'Zip Code must be 5 digits';
        }
        return { zipcode: error };
    },
}

export default validators;
