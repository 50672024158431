import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import View, { ViewProps } from '../common/View';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import { store } from '../initStore';
import { Dropdown } from '../common/Dropdown';
import { LegalEntityTypes } from '../models';
import { AddressForm } from '../common/form/AddressForm';
import { LegalInformationFields, StoreTypes } from '../../interfaces';

import { wholeNumRegExp } from '../../utils/regex';
import validators from '../../utils/validators';

interface IInformationState {
    fields: LegalInformationFields;
    errors: LegalInformationFields;
    activeElement: string;
}

const useLegalInformationState = (props: ViewProps) => {
    const { dbaInformation, isBusiness = false, isPersonal = false } = props;
    const initialValues = { entityType: '', legalName: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', tin: '', yearsInBusiness: '' };
    const initialErrors = { entityType: '', legalName: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', tin: '', yearsInBusiness: '' };
    const initialFieldValues = initialValues;

    if (isPersonal) {
        initialFieldValues.entityType = 'Sole Proprietorship';
    }

    const [legalInformationState, setLegalInformationState] = React.useState<IInformationState>({
        fields: props.legalInformation ? { ...props.legalInformation } : { ...initialFieldValues },
        errors: initialErrors,
        activeElement: null,
    });

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;
            setLegalInformationState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: {...prevState.errors, [name]: null }}));
        }
    }

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({ type: 'legalInformation', payload: { ...legalInformationState.fields } });
            if (isBusiness) {
                props.history.push('/instant/business/owners')
            } else if (isPersonal) {
                props.history.push('/instant/personal/owner')
            }
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for LegalInformation step`, fields: legalInformationState.errors });
        }
    }

    function formIsValid() {
        let newErrors = {} as LegalInformationFields;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error}
        })
        setLegalInformationState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    function elementActive(e) {
        if (e.target) {
            setLegalInformationState(prevState => ({ ...prevState, activeElement: 'tin' }))
        }
    }

    const tinValidation = () => {
        const { fields: { tin = '' }} = legalInformationState;
        const errors = {} as LegalInformationFields;
        if (!tin.trim() && isBusiness) {
            errors.tin = 'TIN/EIN is required';
        } else if (tin.trim() && (tin.length !== 9 || !wholeNumRegExp.test(tin))) {
            errors.tin = 'Must be a 9 digit number'
        } else {
            errors.tin = null;
        }
        return errors;
    }

    const yearsInBusinessValidation = () => {
        const { fields: { yearsInBusiness = '' }} = legalInformationState;
        const errors = {} as LegalInformationFields;
        if (!yearsInBusiness.trim() && isBusiness) {
            errors.yearsInBusiness = 'Years in Business is required';
        } else if(+yearsInBusiness < 0 || +yearsInBusiness > 300 ) {
            errors.yearsInBusiness = 'Invalid: Years must be in range of 0 to 300.'
        }else if (!wholeNumRegExp.test(yearsInBusiness)) {
            errors.yearsInBusiness = 'Round to the nearest number'
        } else {
            errors.yearsInBusiness = null;
        }
        return errors;
    }

    const validations = {
        addressLineOne: () => dbaInformation.isLegalAddress === 'yes' ? {} : validators.addressLineOne(legalInformationState.fields.addressLineOne),
        city: () => dbaInformation.isLegalAddress === 'yes' ? {} : validators.city(legalInformationState.fields.city),
        state: () => dbaInformation.isLegalAddress === 'yes' ? {} : validators.required(legalInformationState.fields.state, 'state', 'State'),
        zipcode: () => dbaInformation.isLegalAddress === 'yes' ? {} : validators.zipcode(legalInformationState.fields.zipcode),
        legalName: () => isPersonal ? {} : validators.oneAlphaRequired(legalInformationState.fields.legalName, 'legalName', 'Legal Name'),
        tin: tinValidation,
        yearsInBusiness: yearsInBusinessValidation,
        entityType: () => isPersonal ? {} : validators.entityType(legalInformationState.fields.entityType),
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            const value =  e.currentTarget.value || e.target.value || '';

            if (validation) {
                const newError = validation(value);
                if (!newError[name]) {
                    console.log(`Saving... ${name} ${value}`);
                }
                setLegalInformationState(prevState => ({ ...prevState, errors: { ...prevState.errors, ...newError }, activeElement: null }));
            } else {
                if (value.trim()) {
                    console.log(`Saving... ${name} - ${value}`);
                }
            }
        }
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({ type: 'legalInformation', payload: { ...legalInformationState.fields } });
        goBack();
    }

    return {
        legalInformationState,
        handleChange,
        handleBack,
        handleSubmit,
        elementActive,
        elementBlur,
    };
}

const LegalInformation = (props: ViewProps) => {
    const {
        legalInformationState,
        handleChange,
        handleSubmit,
        elementBlur,
        handleBack,
        elementActive,
    } = useLegalInformationState(props);

    const { entityType, legalName, tin, yearsInBusiness } = legalInformationState.fields;
    const { fields, errors } = legalInformationState;
    const { dbaInformation, loading = false } = store.getState() as StoreTypes;

    if (!dbaInformation) {
        props.history.push('/instant/signup');
        return null;
    }
    const { isLegalAddress = 'no' } = dbaInformation;

    return <View>
        <Aside
            explainer={<>
                <h1>TELL US WHO OWNS THE BUSINESS</h1>
                <p>Great, now tell us a bit about the legal structure of your business. </p>
            </>}
            field={legalInformationState.activeElement}
        />
        <form className="gp-form" id="#legalInformation" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />
                <div className="form-group">
                    {props.isBusiness && <div className="input-full">
                        <label>Legal Business Name</label>
                        <input className="input-half" value={legalName} onChange={handleChange} onBlur={elementBlur}  placeholder="ABC Company, LLC" type="text" name="legalName" maxLength={30} required />
                        {legalInformationState.errors.legalName && <div className="error-message">{legalInformationState.errors.legalName}</div>}
                    </div>}
                    {props.isBusiness && <Dropdown
                        selected={entityType}
                        selectLabel="Legal Entity Type"
                        selectName="entityType"
                        firstOption="Select a type..."
                        className="input-third"
                        error={legalInformationState.errors.entityType}
                        options={LegalEntityTypes}
                        onChange={handleChange}
                        onBlur={elementBlur}
                        required={props.isBusiness}
                    />}
                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>TIN/EIN</label>
                        <input value={tin} onFocus={elementActive} onBlur={elementBlur} onChange={handleChange} type="text" name="tin" maxLength={9} minLength={9}/>
                        {legalInformationState.errors.tin && <div className="error-message">{legalInformationState.errors.tin}</div>}
                    </div>
                    <div className={props.isBusiness ? 'input-third' : 'input-half'}>
                        <label>Years in Business</label>
                        <input value={yearsInBusiness} onChange={handleChange} onBlur={elementBlur}  name="yearsInBusiness" max="300" min="0" type="number" inputMode="numeric" required />
                        {legalInformationState.errors.yearsInBusiness && <div className="error-message">{legalInformationState.errors.yearsInBusiness}</div>}
                    </div>
                    {isLegalAddress === 'no' ?
                        <AddressForm
                            title="Legal Business Address"
                            fields={fields}
                            onBlur={elementBlur}
                            errors={errors}
                            className="input-full"
                            handleChange={handleChange} /> : null}
                </div>
                <div className="flex">
                    <button type="button" disabled={loading} className="db back mt-auto" onClick={handleBack}>Back</button>
                    <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
                </div>
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ legalInformation: state.legalInformation, dbaInformation: state.dbaInformation });
export default connect(mapStateToProps)(withRouter(LegalInformation));
