import { getPreSignedUrl } from "api";
import { AcceptedFileTypes, DocumentFile, isAcceptedFileType, } from "interfaces";
import React, { useState } from "react";

interface FileInfoProps {
    label: string;
    file?: DocumentFile;
    isEditable?: boolean;
    showPreview?: boolean;
    handleRemove?: () => void;
    handleUpload?: (file: DocumentFile) => void;
    handleInputBlur?: (value: string) => void;
}

const FileInfo: React.FC<FileInfoProps> = (props) => {
    const {
        file,
        label,
        isEditable = true,
        showPreview = true,
        handleRemove,
        handleUpload,
        handleInputBlur,
    } = props;
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleUploadClick = () => {
        inputRef.current.click();
        inputRef.current.value = null;
    };

    const onBlur = (e) => {
        if (e.target || e.currentTarget) {
            const value = e.currentTarget.value || e.target.value || "";
            handleInputBlur(value);
        }
    };

    const handleUploadS3 = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setError(null);
        if (e.target.files && e.target.files.length) {
            const file = e.target.files[0];

            setLoading(true);

            if (file.size > 5 * 1024 * 1024) {
                setLoading(false);
                setError(
                    "The file is too large. The maximum file size is 5 Megabyte"
                );
                return;
            }

            if (!isAcceptedFileType(file.type)) {
                setLoading(false);
                setError("Unsupported file format");
                return;
            }

            try {
                const url: string = await getPreSignedUrl(
                    `${Date.now()}-${file.name}`
                );

                if (url) {
                const response = await fetch(url, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': file.type,
                    },
                });
                console.log(response, 'response');

                handleUpload({
                    name: file.name,
                    contentType: file.type,
                    url: url.split("?")[0],
                });
            }
            } catch (e) {
                setError(e.message || "Failed to upload");
            }
            setLoading(false);
        }
    };

    return (
        <>
            <div className="flex form mt2 items-center">
                {showPreview ? (
                    <a
                        href={file ? file.url : null}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img
                            src="/img/icon-preview.svg"
                            className="mt2"
                            alt="preview"
                        />
                    </a>
                ) : null}
                <input
                    className="input-full dn"
                    ref={inputRef}
                    onChange={handleUploadS3}
                    capture="user"
                    type="file"
                    accept={AcceptedFileTypes.join(",")}
                    name="documents"
                />
                <div className="input-half flex-row ml2">
                    <input
                        defaultValue={label}
                        type="text"
                        name="fileName"
                        maxLength={50}
                        className="bn f7 b--gray text-black"
                        onBlur={onBlur}
                        disabled={!isEditable}
                    ></input>
                </div>
                {isEditable ? (
                    <label className="ml2 mb2 f4">
                        <span className="pointer" onClick={handleRemove}>
                            Remove
                        </span>
                    </label>
                ) : loading ? (
                    <img
                        src="/img/loader.svg"
                        className="ml3 mb2"
                        alt="loading"
                    />
                ) : (
                    <img
                        src="/img/icon-upload.svg"
                        className="ml3 pointer"
                        alt="upload"
                        onClick={handleUploadClick}
                    />
                )}
            </div>
            {error && <div className="error-message">{error}</div>}
        </>
    );
};

export default FileInfo;
