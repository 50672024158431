import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';


import { PersistGate } from 'redux-persist/integration/react'

import Signup from './components/views/Signup';
import Personal from './components/views/Personal';
import Business from './components/views/Business';
import Processing from './components/views/Processing';
import Banking from './components/views/Banking';
import Checkout from './components/views/Checkout';


import { store, persistor } from './components/initStore';
import LeadCapture from './components/common/LeadCapture';
import Loading from './components/common/Loading'
import CreateApp from 'components/views/CreateApp';
import AdditionalDocuments from 'components/views/AdditionalDocuments';
import CreateOpportunity from 'components/views/CreateOpportunity';
import ErrorPage from 'components/views/ErrorPage';

const PageNotFound = () => <h1>Page Not Found</h1>

export default () => {
    return <div className="App">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                    <Switch>
                        <Route exact path="/:partnerName?" component={props => <Signup {...props} />} />
                        <Route path="/instant/signup" component={props => <Signup {...props} />} />
                        <Route path="/instant/error" component={ErrorPage} />
                        <LeadCapture>
                            <Route path="/instant/business" component={props => <Business isBusiness {...props} />} />
                            <Route path="/instant/personal" component={props => <Personal isPersonal {...props} />} />
                            <Route path="/instant/processing" component={props => <Processing {...props} />} />
                            <Route path="/instant/banking" component={props => <Banking {...props} />} />
                            <Route path="/instant/additional-docs" component={props => <AdditionalDocuments {...props} />} />
                            <Route path="/instant/create-app" component={props => <CreateApp {...props} />} />
                            <Route path="/instant/create-opportunity" component={props => <CreateOpportunity {...props} />} />
                            <Route path="/instant/checkout" component={props => <Checkout {...props} />} />
                        </LeadCapture>
                        <Route component={PageNotFound} />
                    </Switch>
                </Router>
                <Loading />
            </PersistGate>
        </Provider>
    </div>
};
