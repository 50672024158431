import React from 'react';

/*
IsOrRadioForm defaults to a Yes-No Radio Form if no values or labels are provided.
*/

interface IIsOrRadioFormProps {
    label: string;
    name: string;
    selected: string;
    values?: string[];
    labels?: string[];
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    onFocus?: any;
}

export const IsOrRadioForm = (props: IIsOrRadioFormProps) => {
    const {
        className = '',
    } = props;

    return (
        <div className={`yes-no-radio-group ${className}`}>
            <label className="db">{props.label}</label>
            <div className="yes-no-radio">
                <input
                    type="radio"
                    id={`${props.name}-yes`}
                    name={props.name}
                    value={!props.values ? 'yes' : props.values[0]}
                    onChange={props.handleChange}
                    onFocus={props.onFocus ? props.onFocus : null}
                    checked={!props.values ? props.selected === 'yes' : props.selected === props.values[0]} />
                <label htmlFor={`${props.name}-yes`}>{!props.labels ? 'Yes' : props.labels[0]}</label>
            </div>
            <div className="yes-no-radio">
                <input
                    type="radio"
                    id={`${props.name}-no`}
                    name={props.name}
                    value={!props.values ? 'no' : props.values[1]}
                    onChange={props.handleChange}
                    onFocus={props.onFocus ? props.onFocus : null}
                    checked={!props.values ? props.selected === 'no' : props.selected === props.values[1]} />
                <label htmlFor={`${props.name}-no`}>{!props.labels ? 'No' : props.labels[1]}</label>
            </div>
        </div>
    )
}
