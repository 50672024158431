import { purgeStore } from './components/initStore'

export default href => {
    // Wipe your state
    purgeStore();

    if (typeof window.top.location !== 'undefined') {
        window.top.location.href = href;
    } else {
        window.location.href = href;
    }
}
