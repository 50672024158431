import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Aside from "../common/Aside";
import StepIndicator from "../common/StepIndicator";
import View, { ViewProps } from "../common/View";
import { store } from "../initStore";
import {
    AdditionalDocFields,
    DocumentFile,
    StoreTypes,
} from "../../interfaces";
import FileInfo from "components/common/form/FileViewer";

type AdditionalDocError = {
    documentError: string;
} & Partial<AdditionalDocFields>;

interface IDocState {
    fields: AdditionalDocFields;
    errors: AdditionalDocError;
    activeElement: string;
}

const PLACEHOLDER_TEXT = "(Optional) Upload Additional Files Not Required";

const useDocumentState = (props: ViewProps) => {
    const initialValues: AdditionalDocFields = {
        documents: [],
        placeHolders: [{ name: PLACEHOLDER_TEXT }],
    };
    const initialErrors: AdditionalDocError = { documentError: "" };

    const [docState, setDocState] = React.useState<IDocState>({
        fields: props.additionalDocs
            ? {
                  ...props.additionalDocs,
                  placeHolders: props.additionalDocs.documents.length
                      ? []
                      : [{ name: PLACEHOLDER_TEXT }],
              }
            : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
    });

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({
                type: "additionalDocs",
                payload: { ...docState.fields },
            });
            const { opportunityId } = store.getState();
            props.history.push(opportunityId ? '/instant/create-opportunity' : '/instant/create-app');
        } else {
            // @ts-ignore
            window._LTracker.push({
                text: `User failed validation for banking step`,
                fields: docState.errors,
            });
        }
    }

    function handleAddFile(data: DocumentFile) {
        docState.fields.placeHolders.pop();

        setDocState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                documents: [...prevState.fields.documents, data],
                placeHolders: [...docState.fields.placeHolders],
            },
            errors: { ...prevState.errors, documentError: null },
        }));
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : "";
        setDocState((prevState) => ({ ...prevState, activeElement: name }));
    }

    function formIsValid() {
        const { documents } = docState.fields;

        let newErrors = {} as AdditionalDocError;

        if(documents.some(doc => !doc.name.trim()))  {
            newErrors = {documentError: 'Document Name is required'};
        }

        setDocState((prevState) => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(
            (field) => field === "" || field === null
        );
    }

    function handleRemoveDocument(index: number) {
        const { documents, placeHolders } = docState.fields;
        const tempDoc = documents;
        tempDoc.splice(index, 1);
        setDocState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                documents: tempDoc,
                placeHolders:
                    !placeHolders.length && !documents.length
                        ? [{ name: PLACEHOLDER_TEXT }]
                        : placeHolders,
            },
        }));
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({
            type: "additionalDocs",
            payload: { ...docState.fields, placeHolders: [] },
        });
        goBack();
    };

    const handleAddDocument = () => {
        setDocState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                placeHolders: [
                    ...prevState.fields.placeHolders,
                    { name: PLACEHOLDER_TEXT },
                ],
            },
        }));
    };

    const handleDocNameChange = (value, i) => {
        const documents = docState.fields.documents;

        const file = documents[i];
        file.name = value;
        documents[i] = file;

        setDocState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                documents: documents,
            },
            errors: { ...prevState.errors, documentError: null },
        }));
    };

    return {
        docState,
        handleBack,
        handleSubmit,
        elementActive,
        handleAddDocument,
        handleAddFile,
        handleDocNameChange,
        handleRemoveDocument,
    };
};

const AdditionlDocuments = (props: ViewProps) => {
    const {
        docState,
        handleSubmit,
        handleBack,
        handleAddFile,
        handleDocNameChange,
        handleAddDocument,
        handleRemoveDocument,
    } = useDocumentState(props);

    const { documents = [], placeHolders = [] } = docState.fields;
    const { loading = false } = store.getState() as StoreTypes;

    const totalFiles = documents.length + placeHolders.length;

    return (
        <View>
            <Aside
                explainer={
                    <>
                        <h1>Upload any additional documents (optional)</h1>
                    </>
                }
                field={docState.activeElement}
            />
            <form
                className="gp-form"
                id="#a"
                noValidate
                onSubmit={handleSubmit}
            >
                <div className="gp-form-elements">
                    <StepIndicator pageStep={store.getState().opportunityId ? 3 : 4} />
                    <div className="form-group">
                        <div className="input-full">
                            <label>
                                Upload any additional documents (optional)
                            </label>
                            <span className="f7 db gray">
                                File must be less than 5MB and Max. 5 files.
                                Allowed file types: .jpeg, .png and .pdf.
                            </span>

                            {documents.length
                                ? documents.map((document, i) => (
                                      <FileInfo
                                          key={i + document.name}
                                          label={document.name}
                                          file={document}
                                          showPreview={false}
                                          handleRemove={() =>
                                              handleRemoveDocument(i)
                                          }
                                          handleInputBlur={(val) =>
                                              handleDocNameChange(val, i)
                                          }
                                      />
                                  ))
                                : null}
                            {placeHolders.length ? (
                                <>
                                    {placeHolders.map((_, i) => (
                                        <FileInfo
                                            key={`${i}-upload`}
                                            label="(Optional) Upload Additional Files Not Required"
                                            handleUpload={handleAddFile}
                                            showPreview={false}
                                            isEditable={false}
                                        />
                                    ))}
                                </>
                            ) : null}

                            {docState.errors.documentError && (
                                <div className="error-message">
                                    {docState.errors.documentError}
                                </div>
                            )}
                            <div className="flex mb3 mt3">
                                <div className="input-half" />
                                <button
                                    className="db add text-black f7"
                                    onClick={handleAddDocument}
                                    type="button"
                                    disabled={totalFiles >= 5}
                                >
                                    Add extra files
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <button
                            disabled={loading}
                            type="button"
                            className="db back mt-auto"
                            onClick={handleBack}
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={loading}
                            className="submit db ml-auto mt-auto self-end"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </form>
        </View>
    );
};
const mapStateToProps = (state) => ({ additionalDocs: state.additionalDocs });
export default connect(mapStateToProps)(withRouter(AdditionlDocuments));
