import React from 'react';
import { StoreTypes } from '../../interfaces';
import { store } from '../initStore';
import { connect } from 'react-redux';

const Loading = () => {

    const { loading = false } = store.getState() as StoreTypes;

    if (loading) {
        return <div role="progressbar" className="mdc-linear-progress mdc-linear-progress--indeterminate loading-indicator">
            <div className="mdc-linear-progress__buffering-dots"></div>
            <div className="mdc-linear-progress__buffer"></div>
            <div className="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                <span className="mdc-linear-progress__bar-inner"></span></div>
            <div className="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                <span className="mdc-linear-progress__bar-inner"></span></div>
        </div>

    }

    return null;
}

function mapStateToProps(state, ownProps) {
    return {
        loading: state.loading,
    };
}

export default connect(mapStateToProps)(Loading);
