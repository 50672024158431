import { useEffect } from 'react';
import { store } from '../initStore';
import { createLeadFromStore } from '../../api';
import { StoreTypes } from '../../interfaces';

const LeadCapture = props => {

    async function onUnload(e: any): Promise<any> {
        e.preventDefault();

        const state = store.getState() as StoreTypes;

        const { signup = null, account = null, opportunityId = null } = state;

        // We would only want to capture the lead if they have filled out the signup, dbaInformation but not submitted their account
        if (account === null && signup !== null && !opportunityId) {
            e.returnValue = '';
            createLeadFromStore();

            store.dispatch({ type: 'loading', payload: { loading: false } });

            // @ts-ignore
            window._LTracker.push(`Capturing lead`);
        }
    }

    useEffect(
        () => {
            window.addEventListener('beforeunload', onUnload);

            return () => { window.removeEventListener('beforeunload', onUnload) }
        }
    )


    return (
        props.children
    )
}

export default LeadCapture;
