import React from 'react';
import uuid from 'uuid/v4';

export type SelectOption = {
    label: string;
    value: string;
}

interface IDropdownProps {
    required?: boolean;
    selected?: string;
    selectLabel?: string;
    selectName: string;
    firstOption?: string;
    options?: SelectOption[];
    className?: string,
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => any;
    onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => any;
    error?: string;
    disabled?: boolean;
}

export const Dropdown = (props: IDropdownProps) => {
    const accessibilityId = uuid();

    const {
        className: classes = 'input-third',
    } = props

    return (
        <div className={classes}>
            <label htmlFor={accessibilityId}>{props.selectLabel}</label>
            <select id={accessibilityId} name={props.selectName} value={props.selected ? props.selected : ''} onChange={props.onChange} onBlur={props.onBlur} disabled={!props.options} required={props.required}>
                { props.firstOption
                    ? <option value="">{props.firstOption}</option>
                    : <option value="" disabled>Select an option...</option>
                }

                {   props.options &&
                    props.options.map((opt, i) => {
                        return (
                            <option value={opt.value} key={i}>{opt.label}</option>
                        )
                    })
                }
            </select>
            {props.error && <div className="error-message">{props.error}</div>}
        </div>
    )
}
