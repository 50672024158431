import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ChangeEvent } from 'cleave.js/react/props';
import uuid from 'uuid/v4';

import Aside from '../../common/Aside';
import StepIndicator from '../../common/StepIndicator';
import View, { ViewProps } from '../../common/View';
import BusinessOwnerForm from '../../common/form/BusinessOwnerForm';
import BusinessOwnerCard, { IBusinessOwnerCard } from '../../common/form/BusinessOwnerCard';
import { BusinessOwnerType, StoreTypes } from '../../../interfaces';
import { store } from '../../initStore';
import validators from '../../../utils/validators';

interface IBusinessOwnersInfoFormState {
    fields: BusinessOwnerType;
    errors: BusinessOwnerType;
    owners: BusinessOwnerType[];
    dobRef: React.ReactInstance;
    ssnRef: React.ReactInstance;
    activeElement: string;
    showForm: boolean;
}

const useOwnersState = (props: ViewProps) => {
    const initialValues = { firstName: '', lastName: '', phoneNumber: '', title: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', ssn: '', percentOwnership: '', dob: '', email: '' };

    const [ownersState, setOwnersState] = React.useState({
        fields: initialValues,
        errors: initialValues,
        owners: props.owners || [],
        dobRef: null,
        ssnRef: null,
        activeElement: null,
        showForm: false,
    } as IBusinessOwnersInfoFormState)

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;
            setOwnersState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value}, errors: {...prevState.errors, [name]: null} }));
        }
    }

    function onDOBInit(cleave: React.ReactInstance) {
        if (cleave) setOwnersState(prevState => ({ ...prevState, dobRef: cleave }));
    }

    function onSSNInit(cleave: React.ReactInstance) {
        if (cleave) setOwnersState(prevState => ({ ...prevState, ssnRef: cleave }));
    }

    function handleDOBChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target ? e.target.value : '';
        setOwnersState(prevState => ({ ...prevState, fields: { ...prevState.fields, dob: value }, errors: {...prevState.errors, dob: null} }));
    }

    function handleSSNChange(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target ? e.target.rawValue || e.target.value : '';
        setOwnersState(prevState => ({ ...prevState, fields: { ...prevState.fields, ssn: value }, errors: {...prevState.errors, ssn: null} }));
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            handleAddOwner();
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for add business owner step`, fields: ownersState.errors });
        }
    }

    function handleContinue() {
        if (ownersState.owners.length && formPercentageIsValid()) {
            store.dispatch({ type: 'owners', payload: [...ownersState.owners] });
            if (store.getState().opportunityId) {
                props.history.push('/instant/banking');
            } else {
                props.history.push('/instant/processing');
            }
        } else {
            // @ts-ignore
            window._LTracker.push(`User failed validation for business owners step`);
        }
    }

    const ssnValidation = () => {
        const { fields: { ssn = '' }} = ownersState;
        const errors = {} as BusinessOwnerType;
        if (!ssn.trim()) {
            errors.ssn = 'SSN is required';
        } else if (ssn.length !== 9) {
            errors.ssn = 'Invalid Social Security Number. Format must follow XXX-XX-XXXX';
        } else {
            errors.ssn = null;
        }
        if (ssn.length === 9 && formHasDuplicateOwner()) {
            errors.ssn = 'Duplicate Owner SSN';
        }
        return errors;
    };

    const validations = {
        email: () => validators.email(ownersState.fields.email),
        addressLineOne: () => validators.addressLineOne(ownersState.fields.addressLineOne),
        city: () => validators.city(ownersState.fields.city),
        state: () => validators.required(ownersState.fields.state, 'state', 'State'),
        zipcode: () => validators.zipcode(ownersState.fields.zipcode),
        firstName: () => validators.oneAlphaRequired(ownersState.fields.firstName, 'firstName', 'First Name'),
        lastName: () => validators.oneAlphaRequired(ownersState.fields.lastName, 'lastName', 'Last Name'),
        ssn: ssnValidation,
        dob: () => validators.dob(ownersState.fields.dob),
        phoneNumber: () => validators.phone(ownersState.fields.phoneNumber, 'phoneNumber'),
        percentOwnership: () => validators.percentOwnership(ownersState.fields.percentOwnership),
        title: () => validators.required(ownersState.fields.title, 'title', 'Title'),
    };

    function handleAddOwner() {
        const newOwner: BusinessOwnerType = { ...ownersState.fields };
        newOwner.id = uuid();

        // @ts-ignore
        ownersState.dobRef.setRawValue(null);
        // @ts-ignore
        ownersState.ssnRef.setRawValue(null);

        setOwnersState(prevState => ({
            ...prevState,
            fields: initialValues,
            errors: initialValues,
            owners: [...prevState.owners, newOwner],
            showForm: !ownersState.showForm,
            activeElement: null,
        }));
    }

    function handleDestroyOwner(val: string) {
        const filtered = ownersState.owners.filter((owner: BusinessOwnerType) => val !== owner.id);
        setOwnersState(prevState => ({ ...prevState, owners: filtered }))
    }

    function resetForm(e: React.MouseEvent) {
        e.preventDefault();
        setOwnersState(prevState => ({
            ...prevState,
            fields: initialValues,
            errors: initialValues,
            showForm: !ownersState.showForm,
            activeElement: null,
        }));
    }

    function formIsValid() {
        let newErrors = {} as BusinessOwnerType;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error}
        })
        setOwnersState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    const formHasDuplicateOwner = () => ownersState.owners.some(owner => owner.ssn === ownersState.fields.ssn);

    const formPercentageIsValid = () => {
        const totalPercentage = ownersState.owners.reduce((acc, next) => acc + +next.percentOwnership, 0);
        return totalPercentage <= 100;
    }

    function elementActive(e) {
        const name = e.target ? e.target.name : '';
        setOwnersState(prevState => ({ ...prevState, activeElement: name }))
    }

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            const value =  e.currentTarget.value || e.target.value || '';

            if (validation) {
                const newError = validation(value);
                if (!newError[name] && value.trim()) {
                    console.log(`Saving... ${name} ${value}`);
                }
                setOwnersState(prevState => ({ ...prevState, errors: { ...prevState.errors, ...newError }, activeElement: null }));
            } else {
                if (value.trim()) {
                    console.log(`Saving... ${name} - ${value}`);
                }
            }
        }
    }

    function handleShowForm() {
        setOwnersState(prevState => ({ ...prevState, showForm: !ownersState.showForm, errors: initialValues }))
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.rawValue || e.currentTarget.value || e.target.value;
            setOwnersState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: {...prevState.errors, [name]: null} }));
        }
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({ type: 'owners', payload: [...ownersState.owners] });
        goBack();
    }

    return {
        ownerState: ownersState,
        handleChange,
        handleBack,
        onDOBInit,
        onSSNInit,
        handleDOBChange,
        handleSSNChange,
        handleSubmit,
        handleContinue,
        handleDestroyOwner,
        resetForm,
        formPercentageIsValid,
        elementActive,
        elementBlur,
        handleShowForm,
        handleCleaveChange,
    }
}

const Owners = (props: ViewProps) => {
    const {
        ownerState,
        handleChange,
        onDOBInit,
        onSSNInit,
        handleDOBChange,
        handleBack,
        handleSSNChange,
        handleSubmit,
        handleContinue,
        handleDestroyOwner,
        resetForm,
        formPercentageIsValid,
        elementActive,
        elementBlur,
        handleShowForm,
        handleCleaveChange,
    } = useOwnersState(props);

    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside
            explainer={<>
                <h1>TELL US WHO OWNS THE BUSINESS</h1>
                <p>It's important to us to know who our clients are, plus its required by the government.</p>
            </>}
            field={ownerState.activeElement}
        />
        <form className="gp-form" id="#businessOwners" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />
                <>
                    <p className="b f6">You’ll need to add at least one owner before continuing.</p>
                    <p className="f7">Please list everyone who owns 25% or more of the business.</p>
                </>
                {!ownerState.showForm ? <>
                        <div className="flex w-100 bb mt3 items-center pb2">
                            <p>Owners</p>
                            <button type="button" className="ml-auto pointer h2 pv1 ph2 f6 b" onClick={handleShowForm}>+ Add Owner</button>
                        </div>
                    </> : <>
                    <div className="flex w-100 bb mt3 items-center pb2">
                        <p>Adding Owner</p>
                        <button type="button" className="ml-auto pointer h2 pv1 ph2 f6 b" onClick={resetForm}>Cancel</button>
                    </div>
                </>}
                {!ownerState.showForm
                    ? ownerState.owners.length > 5 ? <div className="error-message">Cannot add more than 5 Owners</div> : <span className="ml-auto f7">Click here to add Owners or Signers</span>
                    : <span className="ml-auto f7">Click here to cancel adding this owner</span>}

                {ownerState.showForm && <fieldset className="form-group">
                    <BusinessOwnerForm
                        onDOBInit={onDOBInit}
                        onSSNInit={onSSNInit}
                        handleChange={handleChange}
                        handleDOBChange={handleDOBChange}
                        handleSSNChange={handleSSNChange}
                        handleFocus={elementActive}
                        handleBlur={elementBlur}
                        handleCleaveChange={handleCleaveChange}
                        fields={ownerState.fields}
                        errors={ownerState.errors}
                        percentOwnership
                        type="Business"
                    />
                    <button className="ml-auto" type="submit" disabled={ownerState.owners.length >= 5 || !formPercentageIsValid()}>Add</button>
                </fieldset>}
                {!ownerState.showForm && <div className="flex flex-wrap mt3">
                    {ownerState.owners.map((owner: IBusinessOwnerCard) => <BusinessOwnerCard
                        key={owner.id}
                        {...owner}
                        destroyOwner={handleDestroyOwner}
                    />)}
                </div>}
                {!ownerState.showForm && <div className="flex">
                    <button disabled={loading} type="button" className="db back mt-auto" onClick={handleBack}>Back</button>
                    <button type="button" className="submit ml-auto mt-auto self-end" onClick={handleContinue} disabled={!ownerState.owners.length || !formPercentageIsValid() || loading}>Continue</button>
                </div>}
                {!formPercentageIsValid() && <div className="error-message">Total Owners' Percentage exceeds 100%</div>}
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ owners: state.owners });
export default connect(mapStateToProps)(withRouter(Owners));
