import React from 'react'
import { ViewProps } from '../../common/View'
import { Route } from 'react-router'

import LegalInformation from '../LegalInformation';
import DBAInformation from '../DBAInformation';
import Owner from './Owner';

export default ({ match, ...viewProps }: ViewProps) =>
    <>
        <Route exact path={`${match.url}/dba`} component={props => <DBAInformation {...props} {...viewProps} />} />
        <Route exact path={`${match.url}/legal`} component={props => <LegalInformation {...props} {...viewProps} />} />
        <Route exact path={`${match.url}/owner`} component={props => <Owner {...props} {...viewProps} />} />
    </>
