import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ChangeEvent } from 'cleave.js/react/props';

import Aside from '../../common/Aside';
import StepIndicator from '../../common/StepIndicator';
import View, { ViewProps } from '../../common/View';
import BusinessOwnerForm from '../../common/form/BusinessOwnerForm';
import { BusinessOwnerType, StoreTypes } from '../../../interfaces';
import { store } from '../../initStore';
import validators from '../../../utils/validators';

interface IOwnerState {
    fields: BusinessOwnerType;
    errors: BusinessOwnerType;
    dobRef: React.ReactInstance;
    ssnRef: React.ReactInstance;
    activeElement: string;
}

const useOwnerState = (props: ViewProps) => {
    const initialValues = { firstName: '', lastName: '', title: 'Owner', ssn: '', percentOwnership: '100', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', dob: '', email: '' };
    const initialErrors = { firstName: '', lastName: '', title: '', ssn: '', percentOwnership: '', addressLineOne: '', addressLineTwo: '', city: '', state: '', zipcode: '', dob: '', email: '' };
    const [ownerState, setOwnerState] = React.useState<IOwnerState>({
        fields: props.owners ? { ...props.owners[0] } : { ...initialValues },
        errors: initialErrors,
        dobRef: null,
        ssnRef: null,
        activeElement: null,
    });

    function onDOBInit(cleave: React.ReactInstance) {
        if (cleave) setOwnerState(prevState => ({ ...prevState, dobRef: cleave }));
    }

    function onSSNInit(cleave: React.ReactInstance) {
        if (cleave) setOwnerState(prevState => ({ ...prevState, ssnRef: cleave }));
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.currentTarget.value || e.target.value;
            setOwnerState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value }, errors: {...prevState.errors, [name]: null} }));
        }
    }

    function handleDOBChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target) {
            const value = e.target.value;
            setOwnerState(prevState => ({ ...prevState, fields: { ...prevState.fields, dob: value.trim() }, errors: {...prevState.errors, dob: null} }));
        }
    }

    function handleSSNChange(e: ChangeEvent<HTMLInputElement>) {
        if (e.target) {
            const value = e.target.rawValue || e.target.value;
            setOwnerState(prevState => ({ ...prevState, fields: { ...prevState.fields, ssn: value.trim() }, errors: {...prevState.errors, ssn: null} }));
        }
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({ type: 'owners', payload: [{ ...ownerState.fields }] });
            const { legalInformation } = store.getState() as StoreTypes;
            const { firstName, lastName } = ownerState.fields
            legalInformation.legalName = `${firstName} ${lastName}`
            store.dispatch({ type: 'legalInformation', payload: legalInformation });

            if (store.getState().opportunityId) {
                props.history.push('/instant/banking');
            } else {
                props.history.push('/instant/processing');
            }
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for personal owners step`, fields: ownerState.errors });
        }
    }

    function formIsValid() {
        let newErrors = {} as BusinessOwnerType;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error};
        });
        setOwnerState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';
        setOwnerState(prevState => ({ ...prevState, activeElement: name }))
    }

    const validations = {
        email: () => validators.email(ownerState.fields.email),
        addressLineOne: () => validators.addressLineOne(ownerState.fields.addressLineOne),
        city: () => validators.city(ownerState.fields.city),
        state: () => validators.required(ownerState.fields.state, 'state', 'State'),
        zipcode: () => validators.zipcode(ownerState.fields.zipcode),
        firstName: () => validators.oneAlphaRequired(ownerState.fields.firstName, 'firstName', 'First Name'),
        lastName: () => validators.oneAlphaRequired(ownerState.fields.lastName, 'lastName', 'Last Name'),
        ssn: () => validators.ssn(ownerState.fields.ssn),
        dob: () => validators.dob(ownerState.fields.dob),
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            const value =  e.currentTarget.value || e.target.value || '';

            if (validation) {
                const newError = validation(value);
                if (!newError[name] && value.trim()) {
                    console.log(`Saving... ${name} ${value}`);
                }
                setOwnerState(prevState => ({
                    ...prevState,
                    errors: { ...prevState.errors, ...newError },
                    activeElement: null,
                }));
            } else {
                if (value.trim()) {
                    console.log(`Saving... ${name} - ${value}`);
                }
            }
        }
    }

    function handleCleaveChange(e: React.ChangeEvent<any>) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const value = e.target.rawValue || e.currentTarget.value || e.target.value;
            setOwnerState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: value } }));
        }
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({ type: 'owners', payload: [{ ...ownerState.fields }] });
        goBack();
    }

    return {
        ownerState,
        onDOBInit,
        onSSNInit,
        handleChange,
        handleBack,
        handleDOBChange,
        handleSSNChange,
        handleSubmit,
        elementActive,
        elementBlur,
        handleCleaveChange,
    }
}

const Owner = (props: ViewProps) => {
    const {
        ownerState,
        onDOBInit,
        onSSNInit,
        handleChange,
        handleDOBChange,
        handleSSNChange,
        handleSubmit,
        handleBack,
        elementActive,
        elementBlur,
        handleCleaveChange,
    } = useOwnerState(props);

    const { fields, errors } = ownerState;
    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside
            explainer={<>
                <h1>Personal Information</h1>
                <p>Your personal info is required by the IRS and payment-card companies.</p>
            </>}
            field={ownerState.activeElement}
        />
        <form className="gp-form" id="#personalOwners" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={1} />
                <BusinessOwnerForm
                    onDOBInit={onDOBInit}
                    onSSNInit={onSSNInit}
                    handleChange={handleChange}
                    handleDOBChange={handleDOBChange}
                    handleSSNChange={handleSSNChange}
                    handleFocus={elementActive}
                    handleBlur={elementBlur}
                    handleCleaveChange={handleCleaveChange}
                    fields={fields}
                    errors={errors}
                    type="Personal"
                />
                <div className="flex">
                    <button disabled={loading} className="db back mt-auto" onClick={handleBack}>Back</button>
                    <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
                </div>
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ owners: state.owners });
export default connect(mapStateToProps)(withRouter(Owner));
