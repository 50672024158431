import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { store } from '../initStore';

interface IStepIndicatorProps extends RouteComponentProps<{}> {
    pageStep: number;
}

export const StepIndicator = ({ pageStep }: IStepIndicatorProps) => {

    const numberOfPages = store.getState().opportunityId ? 6 : 7;
    const pages = [...Array(numberOfPages).keys()].slice(1).map((page, i) => { // Slice removes 0
        const stage = i === pageStep ? 'current' : i < pageStep ? 'complete' : '';

        return <li key={page} className={stage}>{stage === 'complete' ? <img src="/img/icon-checkmark.svg" alt="" /> : page}</li>
    })

    return <>
        <ul className="gp-form-elements__page-indicator">
            {pages}
        </ul>
    </>
}

export default connect<{}, {}, IStepIndicatorProps>(null, null)(withRouter(StepIndicator));
