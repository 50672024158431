import View from "components/common/View";
import { store } from "components/initStore";
import React from "react";

const ErrorPage: React.FC= () => {
    const { error } = store.getState()
    return (
        <View>
            <div className="gp-form justify-center">
                <div className="self-center f4 b">
                    {error}
                </div>
            </div>
        </View>
    );
};

export default ErrorPage;