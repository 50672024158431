import { USState } from './api/gravity-instant';

export type Account = {
    id: string,
    productPath: string;
    submitted: boolean;
}

export type SignupFields = {
    dba: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    merchantType: string;
}

export type LegalInformationFields = {
    entityType: string;
    legalName: string
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: USState;
    zipcode: string;
    tin: string;
    yearsInBusiness: string;
}

export type DBAFields = {
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: USState;
    zipcode: string;
    phone: string;
    email: string;
    website: string;
    isLegalAddress: string;
}

export type BusinessOwnerType = {
    id?: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    title: string;
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: USState;
    zipcode: string;
    ssn: string;
    dob: string;
    email: string;
    percentOwnership?: string;
}

export type ProcessingFormFields = {
    businessCategory: string;
    businessType: string;
    businessDescription: string;
    currentlyAcceptingCards: string;
    swipedPercentage: string;
    keyedPercentage:string;
    motoPercentage: string;
    ecommPercentage: string;
    averageMonthlyCardVolume: string;
    averageMonthlySalesVolume: string;
    averageTicket: string;
}

export const AcceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'] as const;
type AcceptedFileType = typeof AcceptedFileTypes[number];
export const isAcceptedFileType = (type): type is AcceptedFileType => AcceptedFileTypes.includes(type);
export type DocumentFile = {
    url: string;
    type?: string;
    contentType: AcceptedFileType;
    name: string;
}

export type BankingInfoFields = {
    depositAccount: string;
    depositRouting: string;
    documents: DocumentFile[];
}

export type AdditionalDocFields = {
    documents: DocumentFile[];
    placeHolders: DocumentFile[];
}

export type CheckoutFields = {
    addressLineOne: string;
    addressLineTwo: string;
    city: string;
    state: string;
    zipcode: string;
    billingName: string;
}

export type UtmParams = {
    source: string;
    medium: string;
    campaign: string;
    keyword: string;
    content: string;
}

export type PartnerInfo = {
    name?: string;
    industry?: {
        category: string;
        type: string;
        sicCode: string;
    };
    theme?: {
        primaryColor: string;
        secondaryColor: string;
    }
    products?: string[];
    pricingCode?: string;
}

export type Product = {
    default?: boolean;
    name: string;
    description: string;
    features: string[];
    price: number;
    minQty: number;
    maxQty: number;
    code: string;
    imageSrc: string;
    identifier: string;
}

export type StoreTypes = {
    pricingCode: string;
    account: Account;
    signup: SignupFields;
    legalInformation: LegalInformationFields;
    dbaInformation?: DBAFields;
    owners?: BusinessOwnerType[];
    processing: ProcessingFormFields;
    banking: BankingInfoFields;
    additionalDocs: AdditionalDocFields;
    products: { [key: string]: Product };
    selectedProducts: { [product: string]: number };
    loading: boolean;
    utmParams: UtmParams;
    opportunityId?: string;
    partnerInfo: PartnerInfo;
    error: string;
}
