import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import Aside from '../common/Aside';
import StepIndicator from '../common/StepIndicator';
import View, { ViewProps } from '../common/View';
import { store } from '../initStore';
import { StoreTypes } from '../../interfaces';
import { createAccountFromStore, signingDocuments } from '../../api';
import { useInterval } from '../../utils/useInterval';

import redirect from '../../redirect'

interface ICreateDocusignState {
    creatingAccount: boolean;
    timer: any;
    counter: number;
    loading: boolean;
}

const CreateApp = (props: ViewProps) => {
    const [checkoutState, setCheckoutState] = useState<ICreateDocusignState>({
        creatingAccount: false,
        timer: null,
        counter: 0,
        loading: false,
    });

    const createAppId = useCallback(async () => {
        try {
            const { account } = store.getState() as StoreTypes;
            if (!account || !account.submitted) {
                const res = await createAccountFromStore();
                store.dispatch({
                    type: "account",
                    payload: { id: res.appId, submitted: true },
                });
                // @ts-ignore
                window._LTracker.push(
                    `Successfully created account ${res.appId}`
                );
            }
        } catch (e) {
            console.error("err:", e);
            setCheckoutState((prevState) => ({ ...prevState, timer: null }));
            store.dispatch({ type: "loading" });
            store.dispatch({
                type: "error",
                payload: {
                    error: "Failed to Submit Application. Contact support",
                },
            });
            props.history.push("/instant/error");
        }
    }, [props.history]);

    async function getSigningDocuments() {
        // Retry to get the signing URL for n times.
        if (checkoutState.counter < 121) {
            if ([30, 60, 90, 120].includes(checkoutState.counter)) {
                // @ts-ignore
                try {
                    const { account } = store.getState() as StoreTypes;
                    const { signingUrl } = await signingDocuments({ id: account.id });
                    setCheckoutState(prevState => ({ ...prevState, timer: null }));
                    // @ts-ignore
                    redirect(signingUrl);
                } catch (e) {
                    // We can get a 404 here.
                }
            }
            setCheckoutState(prevState => ({ ...prevState, counter: prevState.counter + 1 }));
        } else {
            setCheckoutState(prevState => ({ ...prevState, timer: null }));
            redirect(process.env.REACT_APP_INSTANT_ERROR);
        }
    }

    useEffect(() => {
        createAppId();
        setCheckoutState(prevState => ({ ...prevState, timer: 1000 }));
    }, [createAppId])
    useInterval(getSigningDocuments, checkoutState.timer)

    return <View>
        <Aside explainer={<>
            <h1>WE ARE PREPARING YOUR APPLICATION</h1>
            <p>In a moment, you will be presented with a DocuSign. Please sign this.</p>
        </>} />
        <form className="gp-form">
            <div className="gp-form-elements">
                <StepIndicator pageStep={5} />
                <div className="loading-text">
                    <p>Creating Application:</p>
                    <p>{`${Math.floor(checkoutState.counter * 0.83)}% Completed`}</p>
                </div>
            </div>
        </form>
    </View>;
}
export default withRouter(CreateApp);
