import React from 'react'
import { ViewProps } from '../../common/View'
import { Route } from 'react-router'

import Owners from './Owners'
import LegalInformation from '../LegalInformation';
import DBAInformation from '../DBAInformation';

export default ({match, ...viewProps}: ViewProps) =>
    <>
        <Route exact path={`${match.url}/dba`} component={props => <DBAInformation {...props} {...viewProps} />}/>
        <Route exact path={`${match.url}/legal`} component={props => <LegalInformation {...props} {...viewProps} />}/>
        <Route exact path={`${match.url}/owners`} component={props => <Owners {...props} {...viewProps} />}/>
    </>
