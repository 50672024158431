import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Cleave from 'cleave.js/react';
import Aside from '../common/Aside';
import FileInfo from 'components/common/form/FileViewer';
import StepIndicator from '../common/StepIndicator';
import View, { ViewProps } from '../common/View';
import { store } from '../initStore';
import { BankingInfoFields, DocumentFile, StoreTypes } from '../../interfaces';
import validators from '../../utils/validators';

type BankFormError = {
    documentError: string
} & Partial<BankingInfoFields>;

interface IBankingFormState {
    fields: BankingInfoFields;
    errors: BankFormError;
    activeElement: string;
}

const useBankState = (props: ViewProps) => {
    const initialValues: BankingInfoFields = { depositAccount: '', depositRouting: '', documents: [] };
    const initialErrors: BankFormError = { depositAccount: '', depositRouting: '', documentError: '' };

    const [bankState, setBankState] = React.useState<IBankingFormState>({
        fields: props.banking ? { ...props.banking } : { ...initialValues },
        errors: initialErrors,
        activeElement: null,
    });

    function handleFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.persist()
        e.preventDefault();
        const name: string = e.target ? e.target.name : '';
        setBankState(prevState => ({ ...prevState, fields: { ...prevState.fields, [name]: e.target ? e.target.value : '' }, errors: {...prevState.errors, [name]: null} }));
    }

    function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (formIsValid()) {
            store.dispatch({ type: 'banking', payload: { ...bankState.fields } });
            props.history.push('/instant/additional-docs');
        } else {
            // @ts-ignore
            window._LTracker.push({ text: `User failed validation for banking step`, fields: bankState.errors });
        }
    }

    function handleAddFile(data: DocumentFile) {
            setBankState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    documents: [
                        ...prevState.fields.documents || [],
                        data,
                    ],
                },
                errors: { ...prevState.errors, documentError: null },
            }));
    }

    function elementActive(e) {
        const name: string = e.target ? e.target.name : '';
        setBankState(prevState => ({ ...prevState, activeElement: name }));
    }

    const documentValidator = () => {
        const { documents } = bankState.fields;
        const errors = {} as BankFormError;

        if(documents.length === 0) {
            errors.documentError = "Document is required."
        } else if(documents.every(doc => !doc.name.trim())) {
            errors.documentError = "Document Name is required."
        } else {
            errors.documentError = null;
        }

        return errors;

    }

    const validations = {
        depositRouting: () => validators.depositRouting(bankState.fields.depositRouting),
        depositAccount: () => validators.depositAccount(bankState.fields.depositAccount),
        document: documentValidator
    };

    function elementBlur(e) {
        if (e.currentTarget || e.target) {
            const name = e.currentTarget.name || e.target.name;
            const validation = validations[name];
            const value =  e.currentTarget.value || e.target.value || '';

            if (validation) {
                const newError = validation(value);
                if (!newError[name]) {
                    console.log(`Saving... ${name} ${value}`);
                }
                setBankState(prevState => ({
                    ...prevState,
                    errors: { ...prevState.errors, ...newError },
                    activeElement: null,
                }));
            } else {
                if (value.trim()) {
                    console.log(`Saving... ${name} - ${value}`);
                }
            }
        }
    }

    function formIsValid() {
        let newErrors = {} as BankFormError;
        Object.values(validations).forEach(validate => {
            const error = validate();
            newErrors = {...newErrors, ...error}
        })
        setBankState(prevState => ({ ...prevState, errors: newErrors }));
        return Object.values(newErrors).every(field => field === '' || field === null);
    }

    function handleRemoveDocument(index: number) {
        const { documents } = bankState.fields
        const tempDoc = documents
        tempDoc.splice(index, 1)
        setBankState(prevState => ({ ...prevState, fields: { ...prevState.fields, documents: tempDoc }}));
    }

    const handleBack = () => {
        const { goBack } = props.history;
        store.dispatch({ type: 'banking', payload: { ...bankState.fields } });
        goBack();
    }

    const handleDocNameChange = (value, i) => {
        const documents = bankState.fields.documents

        const file = documents[i];
        file.name = value;
        documents[i] = file;

        setBankState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                documents: documents,
            },
            errors: { ...prevState.errors, documentError: null },
        }));
    }

    return {
        bankState,
        handleBack,
        handleFieldChange,
        handleDocNameChange,
        handleRemoveDocument,
        handleSubmit,
        handleAddFile,
        elementActive,
        elementBlur,
    };
}

const Banking = (props: ViewProps) => {
    const {
        bankState,
        handleFieldChange,
        handleSubmit,
        handleAddFile,
        handleDocNameChange,
        handleRemoveDocument,
        handleBack,
        elementActive,
        elementBlur,
    } = useBankState(props);

    const { depositAccount, depositRouting, documents = [] } = bankState.fields;
    const { loading = false } = store.getState() as StoreTypes;

    return <View>
        <Aside
            explainer={<>
                <h1>TELL US YOUR BANK INFORMATION</h1>
                <p>Let us know the business checking account you want deposits sent to. This should be the same account as the voided check you’ll upload shortly.</p>
            </>}
            field={bankState.activeElement}
        />
        <form className="gp-form" id="#banking" noValidate onSubmit={handleSubmit}>
            <div className="gp-form-elements">
                <StepIndicator pageStep={store.getState().opportunityId ? 2 : 3} />
                <div className="form-group">
                    <div className="input-full">
                        <label>Deposit Routing Number</label>
                        <Cleave
                            name="depositRouting"
                            value={depositRouting}
                            onBlur={elementBlur}
                            onFocus={elementActive}
                            options={{ numericOnly: true }}
                            onChange={handleFieldChange}
                            required
                            className='input-third'
                            minLength={9}
                            maxLength={9}
                            inputMode="numeric"
                        />
                        {bankState.errors.depositRouting && <div className="error-message">{bankState.errors.depositRouting}</div>}
                    </div>
                    <div className="input-full">
                        <label>Deposit Account</label>
                        <Cleave
                            name="depositAccount"
                            value={depositAccount}
                            onBlur={elementBlur}
                            onFocus={elementActive}
                            options={{ numericOnly: true }}
                            onChange={handleFieldChange}
                            required
                            className='input-third'
                            minLength={3}
                            maxLength={17}
                            inputMode="numeric"
                        />
                        {bankState.errors.depositAccount && <div className="error-message">{bankState.errors.depositAccount}</div>}
                    </div>
                    <div className="input-full">
                        <label>Upload Voided Check or Bank Letter</label>
                        <span className='f7 db gray'>File must be less than 5MB. Allowed file types: .jpeg, .png and .pdf. </span>

                        {documents.length
                                ? documents.map((document, i) => (
                                      <FileInfo
                                          key={i + document.name}
                                          label={document.name}
                                          file={document}
                                          showPreview={false}
                                          handleInputBlur={(e) => handleDocNameChange(e, i)}
                                          handleRemove={() => handleRemoveDocument(i)}
                                      />
                                  ))
                                : null}

                        {documents.length < 1 ? (<>
                                <FileInfo
                                    label="Upload Voided Check or Bank Letter"
                                    handleUpload={handleAddFile}
                                    showPreview={false}
                                    isEditable={false}
                                />
                                </>
                            ) : null}

                        {bankState.errors.documentError && <div className="error-message">{bankState.errors.documentError}</div>}
                    </div>
                </div>
                <div className="flex">
                    <button disabled={loading} type="button" className="db back mt-auto" onClick={handleBack}>Back</button>
                    <button type="submit" disabled={loading} className="submit db ml-auto mt-auto self-end">Continue</button>
                </div>
            </div>
        </form>
    </View>;
}
const mapStateToProps = state => ({ banking: state.banking });
export default connect(mapStateToProps)(withRouter(Banking));
