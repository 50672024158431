import React from 'react';

const helperText = field => {
    switch (field) {
        case 'county':
            return <p> Nothing against other countries, but Gravity Instant is only available in the United States right now. </p>

        case 'ssn':
            return <>
                <h2>Social Security Number</h2>
                <p> Why do we need your SSN? This info is essential for us to be able to process your application and get you up and running. </p>
            </>

        case 'dba':
            return <>
                <h2> </h2>
                <p>“Doing business as” or DBA, is the name of your business customers use. For instance, your coffee shop might be Joe’s Cafe as the DBA name, but the legal name is Joe’s Cafe, LLC.</p>
            </>

        case 'depositAccount':
            return <>
                <h2>Account Number</h2>
                <p> Aside from the routing number, this is the largest of the other 2 groups of numbers on the bottom of your check. (tip: the smaller group is the check number.) </p>
            </>


        case 'depositRouting':
            return <>
                <h2>Routing Number</h2>
                <p> Always a 9-digit number, usually starting on the bottom left corner of your check. This signifies the bank itself. Tip: usually between two symbols that look like this ⑆ </p>
            </>

        case 'checkout':
            return <>
                <h2> </h2>
                <p> After you finish checking out, we’ll direct you to a digital application form to sign and submit. After that you’re all done! </p>
            </>

        case 'tin':
            return <>
                <h2> </h2>
                <p> Your TIN/EIN is like a Social Security Number for your business. This is often called an EIN, FEIN, or ITIN. It’s 9 digits in this format XX-XXXXXXX. It’s not a UBI number. </p>
            </>

        case 'swipedPercentage':
            return <>
                <h2>Swiped</h2>
                <p>These are transactions that are swiped, tapped, or dipped into a device.</p>
            </>

        case 'keyedPercentage':
            return <>
                <h2>Keyed</h2>
                <p>These are transactions that are manually entered into a device.</p>
            </>

        case 'motoPercentage':
            return <>
                <h2>MOTO or Phone Only</h2>
                <p>These are payments made through a telephone or by mail.</p>
            </>

        case 'ecommPercentage':
            return <>
                <h2>Ecommerce</h2>
                <p>These are payments processed through an API by using a shopping cart, virutal terminal, or other web based means.</p>
            </>

        default:
            return null
    }
}

export default ({ explainer, field = null, extra = null }: any) => {
    const helper = helperText(field);

    return <div className="gp-view__sidebar">
        <div className="gp-view__sidebar__intro">
            {explainer}
        </div>
        {extra ?
            <div className="gp-view__sidebar__extra mt3">
                {extra}
            </div>
            : null
        }
        {helper ?
            <div className="gp-view__sidebar__helper-text">
                {helper}
            </div>
            : null
        }
    </div>
}
