import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import Aside from "../common/Aside";
import StepIndicator from "../common/StepIndicator";
import View, { ViewProps } from "../common/View";
import { store } from "../initStore";
import { submitOpportunity } from "../../api";

const CreateOpportunity = (props: ViewProps) => {
    const [isSubmitted, setSubmited] = useState(false);

    useEffect(() => {
        const updateOpportunity = async () => {
            try {
                const res = await submitOpportunity();
                console.log(res, "res");
                setSubmited(true);

                // @ts-ignore
                window._LTracker.push(
                    `Successfully updated opportunity ${res.oppId}`
                );
                store.dispatch({ type: "reset" });
            } catch (err) {
                if (err && err.response && err.response.status === 405) {
                    store.dispatch({
                        type: "error",
                        payload: {
                            error: "Opportunity has already been submitted, contact the support with any questions",
                        },
                    });
                } else {
                    store.dispatch({
                        type: "error",
                        payload: {
                            error: "Failed to Submit opportunity. Contact support",
                        },
                    });
                }
                store.dispatch({ type: "loading" });
                // @ts-ignore
                window._LTracker.push(`Submit opportunity failed ${err}`);
                props.history.push("/instant/error");
            }
        };
        updateOpportunity();
    }, [props.history]);

    if (isSubmitted) {
        return (
            <View>
                <form className="gp-form">
                    <div className="gp-form-elements">
                        <div className="loading-text">
                            <p>Application Submitted Successfully</p>
                        </div>
                    </div>
                </form>
            </View>
        );
    }

    return (
        <View>
            <Aside
                explainer={
                    <>
                        <h1>WE ARE PREPARING YOUR APPLICATION</h1>
                    </>
                }
            />
            <form className="gp-form">
                <div className="gp-form-elements">
                    <StepIndicator pageStep={6} />
                    <div className="loading-text">
                        <p>Submitting Application...</p>
                    </div>
                </div>
            </form>
        </View>
    );
};
export default withRouter(CreateOpportunity);
